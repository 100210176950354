.footer {
    padding: 2rem 0;
    color: #fff;

    h3 {
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 25px;
    }

    .info {
        color: #CBCBCB;
        margin-top: 32px;
        max-width: 50%;
        margin-bottom: 0;
    }

    .links {
        ul {
            padding: 0;
            margin: 0;
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            gap: 1rem 0;

            li {
                width: 50%;

                a {
                    cursor: pointer;
                }
            }
        }
    }

    .contactUs {
        ul {
            padding: 0;
            margin: 0;
            list-style: none;
            display: flex;
            flex-direction: column;
            gap: 1rem 0;

            li {
                display: flex;
                align-items: center;
                gap: 1rem;
                word-break: break-all;
            }
        }
    }

    .copyright {
        align-items: center;

        .socialIcon {
            display: flex;
            align-items: center;
            gap: 0.5rem;

            .icon {
                background-color: #0036A5;
                width: 45px;
                height: 45px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1.5rem;
                border-radius: 0.3rem;
            }
        }

        p {
            font-size: 16px;
            font-weight: 400;
            text-align: right;
            margin: 0;
        }
    }

    hr {
        margin: 2rem 0;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
    .footer {

        .info {
            color: #CBCBCB;
            margin-top: 32px;
            max-width: 100%;
            margin-bottom: 0;
        }

        .copyright {
            gap: 0;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .footer {
        .row {
            gap: 2.5rem;
        }

        .info {
            color: #CBCBCB;
            margin-top: 32px;
            max-width: 100%;
            margin-bottom: 0;
        }

        .copyright {
            gap: 0;
        }
    }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
    .footer {
        .row {
            gap: 2.5rem;
        }

        .footerLogo {
            text-align: center;

            .info {
                color: #CBCBCB;
                margin-top: 32px;
                max-width: 100%;
                margin-bottom: 0;
            }
        }

        .links {
            text-align: center;
            ul {
                gap: 0.5rem 0;

                li {
                    width: 100%;
                }
            }
        }

        .contactUs{
            text-align: center;
            ul{
                li{
                    justify-content: center;
                }
            }
        }

        hr {
            margin: 1.5rem 0;
        }

        .copyright {
            gap: 1rem;

            .socialIcon {
                justify-content: center;
            }

            p {
                text-align: center;
            }
        }
    }
}