@import "../../App.scss";

.home {
    padding: 100px 0 0;

    .row {
        align-items: center;
    }

    .homeContent {
        .homeBtn {
            background-color: #0036A5;
            font-size: 18.67px;
            font-weight: 400;
            color: #ffff;
            padding: 21px 42px;
            border-radius: 5px;
            box-shadow: 0px 1141.33px 320px 0px rgba(0, 0, 0, 0);
            margin-bottom: 57px;
            border: 0;
        }

        h1 {
            font-size: 64px;
            font-weight: 700;
            color: #fff;
            margin-bottom: 57px;

            span {
                color: $theme_color;
            }
        }

        p {
            color: $text_color1;
            font-size: 18px;
            font-weight: 400;
            line-height: 2;
        }

        h3 {
            font-weight: 600;
            color: #fff;
            margin-top: 57px;
        }

        .btnGroup {
            margin-top: 20px;
            display: flex;
            align-items: center;
            gap: 1rem;

            .appBtn {
                border: 1px solid #FFFFFF26;
                background: linear-gradient(169.6deg, #132853 -29.68%, #08090A 17.61%, #08090A 80.74%, #0A2356 102.66%);
                display: flex;
                align-items: center;
                gap: 0.5rem;
                padding: 12px 15px;
                border-radius: 10px;
            }
        }
    }
}

.poweredby {
    padding: 30px 0;

    h3 {
        color: #fff;
        margin-bottom: 40px;
    }

    .item {
        text-align: center;

        &:focus-visible {
            outline: 0;
        }
    }
}

.socialMediaExperience {
    padding: 30px 0;
    padding-left: 223px;

    .row {
        align-items: center;
        margin: 0;
    }

    h1 {
        font-size: 40px;
        font-weight: 700;
        color: #fff;
        margin-bottom: 21px;
    }

    p {
        font-size: 17px;
        font-weight: 400;
        color: #CBCBCB;
        margin: 0;
    }

    .itemBox {
        border: 2.29px solid #FFFFFF26;
        backdrop-filter: blur(45.74361038208008px);
        background: linear-gradient(169.6deg, #132853 -29.68%, #08090A 17.61%, #08090A 80.74%, #0A2356 102.66%);
        padding: 20px;
        text-align: center;
        border-radius: 1rem;
        width: 95% !important;
        margin-right: auto;
        height: 100%;

        .icon {
            border: 2.29px solid #FFFFFF26;
            backdrop-filter: blur(45.74361038208008px);
            background: linear-gradient(169.6deg, #132853 -29.68%, #08090A 17.61%, #08090A 80.74%, #0A2356 102.66%);
            padding: 24px;
            border-radius: 1rem;
            width: max-content;
            margin: auto;
            margin-bottom: 40px;
            height: max-content !important;

            img {
                width: 48px;
                height: 48px;
            }
        }

        h3 {
            font-size: 24px;
            font-weight: 700;
            color: #fff;
        }

        p {
            font-size: 18px;
            font-weight: 400;
            color: #CBCBCB;
            margin-top: 20px;
            margin-bottom: 0;
        }
    }
}

.nextGeneration {
    padding: 30px 0;

    .row {
        align-items: center;
    }

    h1 {
        font-size: 40px;
        font-weight: 700;
        color: #fff;
        margin-bottom: 21px;
    }

    p {
        font-size: 17px;
        font-weight: 400;
        color: #CBCBCB;
        margin: 0;
    }

    ul {
        margin-top: 80px !important;
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 30px;

        li {
            h3 {
                color: #0036A5;
                font-size: 24px;
                font-weight: 700;
                line-height: 55px;
                margin-bottom: 10px;
            }

            p {
                color: #CBCBCB;
                font-size: 18px;
                font-weight: 400;
            }
        }
    }
}

.whyChooseUs {
    padding: 30px 0 100px;

    h1 {
        font-size: 40px;
        font-weight: 700;
        color: #fff;
        margin-bottom: 21px;
    }

    p {
        font-size: 17px;
        font-weight: 400;
        color: #CBCBCB;
        margin: 0;
    }

    /* CircleLayout.css */

    // .circle-container {
    //     position: relative;
    //     width: 100%;
    //     height: 500px;
    //     /* Adjust based on your design requirements */
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;

    //     &::before {
    //         content: '';
    //         position: absolute;
    //         width: 80%;
    //         height: 80%;
    //         border: 1px dashed rgba(255, 255, 255, 0.2);
    //         border-radius: 50%;
    //         z-index: -1;
    //     }

    //     .center-logo {
    //         position: absolute;
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;
    //         background-color: #0d0d0d;
    //         border-radius: 50%;
    //         width: 150px;
    //         /* Adjust based on logo size */
    //         height: 150px;

    //         .logo {
    //             width: 80%;
    //             /* Adjust logo size */
    //             object-fit: contain;
    //         }
    //     }

    //     .card {
    //         position: absolute;
    //         width: 200px;
    //         /* Adjust card width */
    //         padding: 15px;
    //         background-color: #1a1a1a;
    //         color: #fff;
    //         border-radius: 8px;
    //         box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    //         text-align: center;

    //         &.top-left {
    //             top: 10%;
    //             left: 15%;
    //             transform: translate(-50%, -50%);
    //         }

    //         &.top-right {
    //             top: 10%;
    //             right: 15%;
    //             transform: translate(50%, -50%);
    //         }

    //         &.bottom-left {
    //             bottom: 10%;
    //             left: 15%;
    //             transform: translate(-50%, 50%);
    //         }

    //         &.bottom-right {
    //             bottom: 10%;
    //             right: 15%;
    //             transform: translate(50%, 50%);
    //         }
    //     }
    // }

    /* CircleLayout.css */

    .circle-container {
        margin-top: 5rem;
        position: relative;
        width: 100%;
        height: 500px;
        /* Adjust based on your design requirements */
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .center-logo {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #0d0d0d;
        border-radius: 50%;
        width: 150px;
        /* Adjust based on logo size */
        height: 150px;
    }

    .center-logo .logo {
        width: 80%;
        /* Adjust logo size */
        object-fit: contain;
    }

    .card {
        position: absolute;
        width: 40%;
        /* Adjust card width */
        padding: 15px;
        background-color: #1a1a1a;
        color: #fff;
        border-radius: 8px;
        backdrop-filter: blur(45.74361038208008px);
        border: 2px solid #FFFFFF26;
        background: linear-gradient(169.6deg, #132853 -29.68%, #08090A 17.61%, #08090A 80.74%, #0A2356 102.66%);

        h3 {
            font-size: 16px;
            font-weight: 600;
        }

        p {
            font-size: 14px;
            font-weight: 400;
        }

        .icon {
            border: 3px solid var(--white, #FFFFFF);
            box-shadow: 0px 4.68px 70.16px 0px #00000014;
            border-radius: 50%;
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;

            img {
                width: 38px;
                height: 38px;
            }
        }

    }

    .top-left {
        top: 10%;
        left: 0;
        transform: translate(0%, 0%);

        .icon {
            right: 0;
            bottom: -70px;
        }
    }

    .top-right {
        top: 10%;
        right: 0;
        transform: translate(0%, 0%);

        .icon {
            bottom: -70px;
            left: 0;
        }
    }

    .bottom-left {
        bottom: 10%;
        left: 0;
        transform: translate(0%, 0%);

        .icon {
            right: 0;
            bottom: -70px;
        }
    }

    .bottom-right {
        bottom: 10%;
        right: 0;
        transform: translate(0%, 0%);

        .icon {
            bottom: -70px;
            left: 0;
        }
    }

    /* Optional: Add circular background dots for visual effect */
    .circle-container {
        &::before {
            content: '';
            position: absolute;
            border: 2px dashed var(--primary, #0036A5);
            border-radius: 50%;
            z-index: -1;
            width: 300px;
            height: 300px;
            opacity: 0.6;
        }

        &::after {
            content: '';
            position: absolute;
            border: 2px dashed var(--primary, #0036A5);
            border-radius: 50%;
            z-index: -1;
            width: 500px;
            height: 500px;
            opacity: 0.6;
        }
    }

}

.whatClientsSay {
    padding: 30px 0;

    .titleHed {
        margin-bottom: 75px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h1 {
            font-size: 40px;
            font-weight: 700;
            color: #fff;
            margin-bottom: 21px;
        }

        p {
            font-size: 17px;
            font-weight: 400;
            color: #CBCBCB;
            margin: 0;
        }

        .btnGroup {
            margin-top: auto;
            gap: 10px;
            display: flex;
            align-items: center;

            .themeBtn {
                width: 64px;
                height: 64px;
                border-radius: 50%;
                background-color: $theme_color;
                border: 0;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    width: 32px;
                    height: 32px;
                }

                &.white {
                    color: $theme_color;
                    background-color: #fff;
                }
            }
        }
    }

    .box {
        background: linear-gradient(169.6deg, #132853 -29.68%, #08090A 17.61%, #08090A 80.74%, #0A2356 102.66%);
        border: 2.29px solid #FFFFFF26;
        backdrop-filter: blur(45.74361038208008px);
        padding: 40px;
        border-radius: 20px;
        width: 95% !important;
        margin: auto;
        display: block !important;

        .profile {
            display: flex;
            align-items: center;
            gap: 1rem;
            margin-bottom: 24px;
            height: max-content !important;

            img {
                width: 48px;
                height: 48px;
                border-radius: 50%;
            }

            h5 {
                font-size: 18px;
                font-weight: 500;
                color: #fff;
            }
        }

        p {
            font-size: 16px;
            font-weight: 400;
            color: #FFFFFF;
        }
    }
}

.app {
    padding: 30px 0;

    .box {
        // max-height: 680px;
        border: 1px solid #3C475C;
        border-radius: 25px;
        padding: 0 1rem;
        background-image: url(../../Assets/appbackground.png);
        background-size: cover;
        background-position: center;
        position: relative;
        overflow: hidden;

        &::after {
            content: "";
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;
            background: linear-gradient(98.87deg, rgba(9, 29, 68, 0.91) 0.52%, rgba(0, 0, 0, 0.91) 51.09%, rgba(9, 29, 68, 0.91) 99.67%);
        }

        .row {
            align-items: center;
            position: relative;
            z-index: 99;

            .leftSide {
                padding-left: 50px;

                h1 {
                    font-size: 40px;
                    font-weight: 700;
                    color: #fff;
                    margin-bottom: 21px;
                }

                p {
                    font-size: 17px;
                    font-weight: 400;
                    color: #CBCBCB;
                    margin: 0;
                }

                .btnGroup {
                    margin-top: 30px;
                    display: flex;
                    align-items: center;
                    gap: 1rem;

                    .appBtn {
                        border: 1px solid #FFFFFF26;
                        background: linear-gradient(169.6deg, #132853 -29.68%, #08090A 17.61%, #08090A 80.74%, #0A2356 102.66%);
                        display: flex;
                        align-items: center;
                        gap: 0.5rem;
                        padding: 12px 15px;
                        border-radius: 10px;
                    }
                }
            }

            .columnSlider {
                display: flex;
                align-items: center;
                width: 100%;
                gap: 1rem;

                .slide {
                    border: 6.64px solid #FFFFFF;
                    box-shadow: 0px 29.06px 53.14px 0px #4D4D4D33;
                    border-radius: 19.93px;
                    margin: 0.5rem 0;
                    // width: 229px;
                    // height: 498px;

                    img {
                        border-radius: 19.93px;
                    }
                }
            }
        }
    }
}

.customeslider {
    .slick-track {
        display: flex;

        .slick-slide {
            height: auto;

            div {
                height: 100%;
            }
        }
    }
}

@media only screen and (min-width: 1201px) and (max-width: 1440px) {
    .home {
        .homeContent {
            .homeBtn {
                margin-bottom: 30px;
            }

            h1 {
                font-size: 54px;
                margin-bottom: 30px;
            }

            h3 {
                margin-top: 30px;
            }
        }
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
    .home {
        .homeContent {
            .homeBtn {
                font-size: 16px;
                padding: 0.5rem 1rem;
                margin-bottom: 1.5rem;
            }

            h1 {
                font-size: 29px;
                font-weight: 600;
                line-height: 1.5;
                margin-bottom: 1.5rem;
            }

            p {
                font-size: 15px;
            }

            h3 {
                margin-top: 1.5rem;
                font-size: 20px;
                line-height: 1.5;
            }
        }
    }

    .socialMediaExperience {
        padding-left: 20px;

        .row {
            gap: 1rem 0;
        }

        // .itemBox {
        //     margin-left: auto;
        //     display: block !important;
        // }
    }

    .nextGeneration {

        ul {
            margin-top: 20px !important;
            gap: 10px;

            li {
                p {
                    font-size: 15px;
                }
            }
        }
    }

    .whyChooseUs {
        .circle-container {
            margin-top: 1rem;
        }
    }

    .app {
        .box {
            .row {
                .leftSide {
                    padding-left: 0px;
                    padding-bottom: 1rem;
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .home {
        .row {
            flex-direction: column-reverse;
            gap: 1rem;
        }

        .homeContent {
            .homeBtn {
                padding: 0.5rem 1rem;
                margin-bottom: 1.5rem;
            }

            h1 {
                font-size: 33px;
                margin-bottom: 1.5rem;
            }

            h3 {
                margin-top: 1.5rem;
            }
        }
    }

    .socialMediaExperience {
        padding-left: 0;

        .row {
            gap: 1rem 0;
        }

        h1 {
            font-size: 33px;
            font-weight: 600;
            margin-bottom: 1.5rem;
        }

        .itemBox {
            margin-left: auto;
            display: block !important;
        }
    }

    .nextGeneration {

        ul {
            margin-top: 20px !important;
            gap: 10px;

            li {
                p {
                    font-size: 15px;
                }
            }
        }

        &.one {
            .row {
                flex-direction: column-reverse;
            }
        }
    }

    .whyChooseUs {
        padding: 30px 0;

        .circle-container {
            margin-top: 1rem;
            height: max-content;
            flex-wrap: wrap;
            gap: 1rem;

            &::before,
            &::after {
                display: none;
            }
        }

        .center-logo {
            position: relative;
        }

        .card {
            position: relative;
            width: 100%;

            .icon {
                position: relative;
                bottom: 0;
            }
        }
    }

    .whatClientsSay {
        padding: 30px 0 0;

        .titleHed {
            gap: 1rem;
            margin-bottom: 25px;

            .btnGroup {
                margin-left: auto;

                .themeBtn {
                    width: 44px;
                    height: 44px;
                }
            }
        }

        .box {
            display: block !important;
            margin: auto !important;
            padding: 20px;

            .profile {
                margin-bottom: 10px;
            }
        }
    }

    .app {
        .box {
            .row {
                gap: 1.5rem;
                flex-direction: column-reverse;

                .leftSide {
                    padding-left: 0px;
                    padding-bottom: 1rem;
                }
            }
        }
    }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
    .home {
        .row {
            flex-direction: column-reverse;
            gap: 1rem;
        }

        .homeContent {
            text-align: center;
            .homeBtn {
                font-size: 16px;
                padding: 0.5rem 1rem;
                margin-bottom: 1.5rem;
            }

            h1 {
                font-size: 29px;
                font-weight: 600;
                line-height: 1.5;
                margin-bottom: 1.5rem;
            }

            p {
                font-size: 15px;
            }

            h3 {
                margin-top: 1.5rem;
                font-size: 20px;
                line-height: 1.5;
            }
        }
    }

    .poweredby {
        h3 {
            text-align: center;
        }
    }

    .socialMediaExperience {
        padding-left: 0;

        .row {
            gap: 1rem 0;
        }

        h1 {
            font-size: 29px;
            font-weight: 600;
            margin-bottom: 1.5rem;
            text-align: center;
        }

        p {
            font-size: 15px;
            text-align: center;
        }

        .itemBox {
            margin-left: auto;
            display: block !important;

            .icon {
                padding: 14px;
                margin-bottom: 30px;
            }

            h3 {
                font-size: 18px;
            }

            p {
                font-size: 14px;
            }
        }
    }

    .nextGeneration {
        h1 {
            font-size: 29px;
            font-weight: 600;
            margin-bottom: 1.5rem;
            text-align: center;
        }

        p {
            font-size: 15px;
            margin-bottom: 10px;
            text-align: center;
        }

        ul {
            margin-top: 20px !important;
            gap: 10px;

            li {
                h3 {
                    text-align: center;
                }

                p {
                    font-size: 15px;
                }
            }
        }

        &.one {
            .row {
                flex-direction: column-reverse;
            }
        }
    }

    .whyChooseUs {
        padding: 30px 0;

        h1 {
            font-size: 29px;
            font-weight: 600;
            margin-bottom: 1.5rem;
            text-align: center;
        }

        p {
            font-size: 15px;
            margin-bottom: 10px;
            text-align: center;
        }

        .circle-container {
            margin-top: 1rem;
            height: max-content;
            flex-wrap: wrap;
            gap: 1rem;

            &::before,
            &::after {
                display: none;
            }
        }

        .center-logo {
            position: relative;
        }

        .card {
            position: relative;
            width: 100%;
            top: 0;
            bottom: 0;

            h3 {
                text-align: center;
            }

            .icon {
                position: relative;
                bottom: 0;
                margin: auto auto 1rem;
            }
        }
    }

    .whatClientsSay {
        padding: 30px 0 0;

        .titleHed {
            flex-direction: column;
            gap: 1rem;
            margin-bottom: 25px;

            h1 {
                font-size: 29px;
                font-weight: 600;
                margin-bottom: 1.5rem;
                text-align: center;

            }

            p {
                font-size: 15px;
                margin-bottom: 10px;
                text-align: center;

            }

            .btnGroup {
                // margin-left: auto;

                .themeBtn {
                    width: 44px;
                    height: 44px;
                }
            }
        }

        .box {
            display: block !important;
            margin: auto !important;
            padding: 20px;

            .profile {
                margin-bottom: 10px;
            }

            p {
                font-size: 14px;
            }
        }
    }

    .app {
        .box {
            .row {
                gap: 1.5rem;
                flex-direction: column-reverse;

                .leftSide {
                    padding-left: 0px;
                    padding-bottom: 1rem;

                    h1 {
                        font-size: 20px;
                    }

                    p {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}