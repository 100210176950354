.privacy-policy {
    padding: 100px 0 0;

    .topBanner {
        min-height: 300px;
        display: flex;
        align-items: center;
        color: #fff;
        background-image: url(../../Assets/privacypolicybanner.jpg);
        background-size: cover;

        h1 {
            font-weight: 600;
            margin: 0;
        }

        .breadcrumb {
            margin-top: 1.5rem;

            a {
                color: #fff;
            }

            .breadcrumb-item {
                &.active {
                    color: #aab1c9;
                }

                &::before {
                    font-weight: 600;
                    color: #e10000;
                }
            }
        }
    }

    .mainContent {
        padding: 60px 0 30px;
        color: #fff;

        .info {
            margin-bottom: 2rem;

            &:last-child {
                margin: 0;
            }

            h2 {
                font-weight: 700;
                margin-bottom: 1rem;
            }

            p {
                font-size: 18px;
                line-height: 2;
            }

            ul {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                padding-left: 1rem;

                li {
                    line-height: 1.7;
                }
            }
        }
    }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
    .privacy-policy {
        .topBanner {
            min-height: 200px;
            background-position: center;
            background-size: cover;
        }

        .mainContent {
            .info {
                h2 {
                    font-size: 20px;
                }

                p {
                    line-height: 1.5;
                    font-size: 15px;
                }

                ul {
                    li {
                        font-size: 15px;
                    }
                }
            }
        }
    }
}