.navbar {
    background-color: #0c1321;
    min-height: 100px;
    .navbar-brand {
        margin: 0;
    }

    .navbar-toggler {
        background-color: #fff;
        padding: 0.2rem 0.3rem;
    }

    .navbar-collapse {
        .navbar-nav {
            gap: 60px;

            .nav-item {
                position: relative;
                cursor: pointer;

                .nav-link {
                    font-size: 16px;
                    font-weight: 500;
                    color: #fff;
                    padding: 0;
                }

                &.active{
                    .activeIndicate {
                        transform: translateY(0);
                        opacity: 1;
                    }
                }

                &:hover{
                    .activeIndicate {
                        transform: translateY(0);
                        opacity: 1;
                    }
                }

                .activeIndicate {
                    position: absolute;
                    bottom: -10px;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background-color: var(--primary, #0036A5);
                    transform: translateY(10px);
                    opacity: 0;
                    transition: all 500ms;

                    &::after {
                        content: "";
                        width: 10px;
                        height: 10px;
                        display: inline-block;
                        background-color: var(--primary, #0036A5);
                        top: -4px;
                        position: absolute;
                        left: 0;
                        transform: rotate(45deg);
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
    .navbar {
        .navbar-collapse {
            .navbar-nav {
                gap: 30px;

                .nav-item {
                    .nav-link {
                        font-size: 15px;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .navbar {
        .navbar-brand {
            img {
                height: 55px;
            }
        }

        .navbar-collapse {
            padding: 1rem;
            background-color: #5471cf;
            border-radius: 0.5rem;

            .navbar-nav {
                gap: 1rem;
            }
        }
    }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
    .navbar {
        .navbar-brand {
            img {
                height: 55px;
            }
        }

        .navbar-collapse {
            padding: 1rem;
            background-color: #5471cf;
            border-radius: 0.5rem;

            .navbar-nav {
                gap: 1rem;
            }
        }
    }
}