@import "./Common/common.scss";
@import url('https://fonts.googleapis.com/css2?family=Allura&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';

* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &::-webkit-scrollbar {
    background: #0036A5;
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #f4f4f477;
    border-radius: 5px;
  }
}

a {
  text-decoration: none !important;
}

.fa,
.far,
.fas {
  font-family: "Font Awesome 5 Free" !important;
}

.container {
  max-width: 1469px !important;
}

@media only screen and (min-width: 1201px) and (max-width: 1440px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1320px !important;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {

  .container,
  .container-md,
  .container-sm {
    max-width: 720px !important;
  }
}